import { useEffect, useState } from 'react';
import { Routes, Route, Outlet, Link, redirect, useNavigate, useSearchParams, useLocation } from "react-router-dom";

import Hotjar from '@hotjar/browser';
import ReactPixel from 'react-facebook-pixel';

import Home from './components/Home';
import ThankYou from './components/ThankYou';
import Questionnaire from './components/Questionnaire';

import GewichtVerliezen from './pages/GewichtVerliezen';
import Gids from './pages/Gids';
import Aankomen from './pages/Aankomen';
import JeBentBegonnen from './components/BlackFriday/JeBentBegonnen';
import Payment from './pages/Payment';
import LP from './pages/LP';
// import Homepage from './pages/Homepage';
import Namaak from './pages/Namaak';
import Persuasive from './pages/Persuasive';
import BlackFriday from './pages/BlackFriday';
import BlackFridaySimple from './pages/BlackFridaySimple';
import Quiz from './pages/Quiz';
import QuizFinishedExtended from './components/Quiz/QuizFinished-Extended';
import BetalingGelukt from './components/Quiz/BetalingGelukt';
import BetalingMislukt from './components/Quiz/BetalingMislukt';
import Bundle from './components/Quiz/Bundle';
import GratisConsultatie from './components/Quiz/GratisConsultatie';
import OfferGidsen from './components/Quiz/OfferGidsen';
import PlusGratisCoaching from './components/Quiz/PlusGratisCoaching';
import BeginnersSchema from './components/BeginnersSchema/BeginnersSchema';
import WetenschappelijkSchema from './components/WetenschappelijkSchema/WetenschappelijkSchema';



import Homepage from './components/pages/Homepage/Homepage';

import './custom.css';
import Nav from './components/Nav';
import Footer from './components/pages/Footer';
import CallToAction from './components/Gids/CallToAction';
// import Fullbody from './components/Fullbody/Fullbody';




import Fullbody from './components/DrukkeVaders/Fullbody';
import Custom from './components/Custom/Custom';

import Page from './components/SpiermassaChallenge/Page';
import BeginnersPage from './components/BeginnersChallenge/Page';
import Trainingsplan from './components/Trainingsplan/Page';
import CoachingApp from './components/CoachingApp/Page';
import CoachingAppGratis from './components/CoachingAppGratis/Page';
import Proefpakket from './components/Proefpakket/Page';
import OverOns from './components/pages/OverOns'; 
import Diensten from './components/pages/Diensten';

import TrainingsProduct from './components/products/Trainingsplan';
import Voedingsplan from './components/products/Voedingsplan';
import MassaBouwen from './components/products/MassaBouwen';
import VetVerliezen from './components/products/VetVerliezen';
import axios from 'axios';


function App() {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [altPrice, setAltPrice] = useState(false);

  useEffect(function() {
    if (searchParams.get("page")) {
      navigate(`/${searchParams.get("page")}`);
    }
    
    if (searchParams.get("payment")) {
      console.log('Payment');
      navigate(`/payment`);
    }
    
    if (searchParams.get("prijs")) {
      console.log('Prijs');
      setAltPrice(true);
    }

    if (searchParams.get("paymentResponse")) {
      if (searchParams.get("paymentResponse") == 'success') {
        navigate(`/betaling-gelukt?naam=${searchParams.get("naam")}&email=${searchParams.get("email")}`);
      } else {
        navigate(`/betaling-mislukt`);
      }
    }
  }, [searchParams]);

  const siteId = 3526138;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  ReactPixel.init('336340599280167', options);
  ReactPixel.pageView(); // For tracking page view
  // ReactPixel.track(event, data);

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const location = useLocation();
  // Check if the current path is the homepage or trainingsplan page
  const showNavigation = location.pathname === '/' || 
    location.pathname === '/trainingsplan' || 
    location.pathname === '/voedingsplan' || 
    location.pathname === '/massa-bouwen' || 
    location.pathname === '/vet-verliezen';

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
  };

  const submitForm = async (e) => {
    e.preventDefault();  // This line is crucial
    setIsSubmitting(true);
    setSubmitStatus(null);
  
    try {
      const response = await axios.post('https://hook.eu2.make.com/vsihku3mvayhv2gcxy69f2s19p48nnkx', formData);
      console.log('Form submitted successfully:', response.data);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' }); // Reset form
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="App">

      {showNavigation && (
        <nav className="fixed bg-black top-0 border-b border-white/15 mt-0" style={{ zIndex: '999' }}>
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <div className="text-2xl font-bold">
              <a href="/">
                <img src="/img/hlp-logo-wit.svg" alt="Logo Healthy Living Project" className="w-[150px]" />
              </a>
            </div>
            <div className="items-center space-x-8">
              <a href="/trainingsplan" className="text-white hover:text-primary-red font-medium hidden md:inline-block">
                Hulp bij trainen
              </a>
              <a href="/voedingsplan" className="text-white hover:text-primary-red font-medium hidden md:inline-block">
                Hulp bij voeding
              </a>
              <button onClick={toggleModal} className="text-white hover:text-primary-red font-medium px-4 pt-2 pb-[10px] bg-primary-red rounded-[6px] text-sm">
                Stel je vraag
              </button>
            </div>
          </div>
        </nav>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 mx-[15px] md:mx-[0px]" style={{ zIndex: '9999' }}>
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
            <button onClick={toggleModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              &times;
            </button>
            <h2 className="text-2xl text-black font-bold mb-4">Stel je vraag</h2>
            <p className="mb-4 text-gray-700">
              Heb je vragen over onze diensten of wil je meer informatie? Vul het formulier in en we nemen zo snel mogelijk contact met je op.
            </p>
            <form onSubmit={submitForm}>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                  Naam
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw naam"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                  E-mail
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw e-mail"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
                  Bericht
                </label>
                <textarea
                  id="message"
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-primary-red"
                  placeholder="Jouw bericht"
                  rows="4"
                  required
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-primary-red text-white px-4 py-2 rounded hover:bg-red-600 disabled:bg-gray-400"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Versturen...' : 'Verstuur'}
              </button>
              {submitStatus === 'success' && (
                <p className="mt-2 text-green-600">Bericht succesvol verzonden!</p>
              )}
              {submitStatus === 'error' && (
                <p className="mt-2 text-red-600">Er is een fout opgetreden. Probeer het later opnieuw.</p>
              )}
            </form>
          </div>
        </div>
      )}

      <Routes>
        <Route exact index element={<Homepage defaultPrice={29.99} />} />
        <Route path="trainingsplan" element={<TrainingsProduct />} /> 
        <Route path="voedingsplan" element={<Voedingsplan />} /> 
        <Route path="massa-bouwen" element={<MassaBouwen />} /> 
        <Route path="vet-verliezen" element={<VetVerliezen />} /> 

        {/* <Route exact index element={<BeginnersPage defaultPrice={31.99} />} /> */}
        {/* <Route exact index element={<BeginnersPage defaultPrice={31.99} />} /> */}
        <Route path="60-dagen-trainingsplan" element={<Trainingsplan defaultPrice={29.99} />} /> 
        <Route path="coaching-app" element={<CoachingApp defaultPrice={29.99} />} /> 
        <Route path="coaching-app-trial" element={<CoachingAppGratis defaultPrice={19.99} />} /> 
        <Route path="spiergroei-proefpakket" element={<Proefpakket defaultPrice={19.99} />} /> 

        {/* <Route path="zichtbare-spiermassa-in-60-dagen" element={<BeginnersPage defaultPrice={31.99} />} />  */}
        

        {/* <Route path="over-ons" element={<OverOns />} />  
        <Route path="diensten" element={<Diensten />} />   */}

        {/* <Route path="custom" element={<Fullbody defaultPrice={9.99} />} />   */}

        {/* <Route path="meer-spiermassa-in-6-weken-challenge" element={<Page defaultPrice={altPrice ? 29.99 : 14.99} />} /> 
        <Route path="meer-spiergroei-in-60-dagen" element={<Page defaultPrice={31.99} />} /> 

        <Route path="schema-voor-drukke-vaders" element={<Fullbody defaultPrice={9.99} />} /> 

        <Route path="3-dagen-per-week" element={<Fullbody defaultPrice={9.99} />} /> 
        <Route path="3-dagen-per-week-met-korting" element={<Fullbody defaultPrice={9.99} />} /> 

        <Route path="wetenschappelijk-schema" element={<WetenschappelijkSchema />} /> 
        <Route path="beginners-schema" element={<BeginnersSchema />} />  */}

        {/* <Route exact index element={<QuizFinishedExtended />} /> */}
        {/* <Route path="gidsen" element={<OfferGidsen />} /> 
        <Route path="exclusieve-aanbieding" element={<PlusGratisCoaching />} /> 
        <Route path="exclusieve-aanbieding-30" element={<PlusGratisCoaching price={29.99} geenTwv={true} />} />  */}



        {/* <Route path="homepage" element={<Homepage />} />
        <Route path="quiz-voltooid" element={<QuizFinishedExtended />} />  */}


        {/* <Route index element={<Aankomen />} /> */}

        <Route path="thank-you" element={<ThankYou />} /> 
        <Route path="payment" element={<Payment />} /> 
        <Route path="gewicht-verliezen" element={<GewichtVerliezen />} /> 
        <Route path="aankomen-in-gewicht" element={<Aankomen />} /> 
        <Route path="de-gewichtstoename-fix" element={<Gids />} /> 
        <Route path="je-bent-begonnen" element={<JeBentBegonnen />} /> 
        <Route path="intake" element={<Questionnaire />} /> 

        <Route path="betaling-gelukt" element={<BetalingGelukt />} /> 
        <Route path="betaling-mislukt" element={<BetalingMislukt />} />
        
        <Route path="bundle" element={<Bundle />} />  
      </Routes>

      {/* <CallToAction /> */}

      <Footer />

    </div>
  );
}

export default App;
