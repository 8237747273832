const USPs = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="usps">
            {/* <div className="usp-item">
              <h5>Unieke begeleiding</h5>
              <p>
                1-op-1 <i>ONLINE</i> begeleiding van een expert op het gebied van spieropbouw bij mannen
              </p>
            </div> */}
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Snelle en Gerichte Resultaten</h5>
              <p>
              Binnen slechts 4 weken behaal je tastbare vooruitgang door een doelgericht plan dat volledig is afgestemd op jouw persoonlijke doelen en behoeften.
              </p>
            </div>
            {/* <div className="usp-item">
              <h5>Geen strikt voedingsschema</h5>
              <p>
              Geen gedoe met calorieën tellen of strikte diëten. Je leert hoe je met minimale aanpassingen grote resultaten kunt behalen.              </p>
            </div> */}
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Duidelijke Structuur, Maximale Focus</h5>
              <p>
              Met een bewezen aanpak en concrete stappen zorg je ervoor dat je geen tijd verspilt, maar juist optimaal resultaat boekt met minimale afleiding.</p>
            </div>
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Persoonlijke Ondersteuning</h5>
              <p>
              Je staat er niet alleen voor: met op maat gemaakte ondersteuning blijf je gemotiveerd, gefocust en op koers gedurende de hele reis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default USPs;