
import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import './Page.css';

import axios from 'axios';

// Import Swiper React components
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function ReviewsList() {
  const { globalState, updateGlobalState } = useContext(AppContext);

  const recensies = [
    {
      "titel": "Top",
      "recensie": "Na maar 4 weken voelde ik me sterker dan ooit! Het schema en de coaching zijn echt top.",
      "naam": "Michael",
      "hoe_lang_geleden": "5 dagen geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Volledige begeleiding",
      "recensie": "Ik heb nog nooit zoveel geleerd over voeding en training in zo'n korte tijd. De begeleiding was top en ik voelde me volledig ondersteund.",
      "naam": "Michel",
      "hoe_lang_geleden": "2 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Coaching",
      "recensie": "De persoonlijke aanpak maakte het verschil. Ik voel me sterker en zelfverzekerder.",
      "naam": "Mike",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Aanrader",
      "recensie": "De wekelijkse feedback motiveerde me om door te gaan. Echt een aanrader!",
      "naam": "Sander a",
      "hoe_lang_geleden": "2 dagen geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Personal trainer",
      "recensie": "Het voelt alsof je een persoonlijke trainer bij je hebt, maar dan betaalbaar.",
      "naam": "David R.",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Geweldige structuur",
      "recensie": "De structuur van dit programma is geweldig. Ik voelde me vanaf dag één volledig ondersteund en de resultaten spreken voor zich.",
      "naam": "Govert R.",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Bedankt",
      "recensie": "Stef heeft mij elke week workouts klaargezet en ze werden aangepast wanneer dat nodig was. Echt top resultaten gehad!!",
      "naam": "Alex",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Positief verrast",
      "recensie": "Ik was in het begin sceptisch, maar de resultaten hebben me echt verrast. Mijn lichaam heeft zich zichtbaar verbeterd en mijn vrienden merken het ook.",
      "naam": "Lorenzo K.",
      "hoe_lang_geleden": "2 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Eenvoudig te volgen",
      "recensie": "De eenvoud en effectiviteit van dit programma zijn geweldig.",
      "naam": "Mick",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Blijvend resultaat",
      "recensie": "De begeleiding tijdens de challenge heeft me veel geleerd over training. Dit zal me nog lang helpen bij mijn fitness.",
      "naam": "Tim",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Heel handig",
      "recensie": "De app maakt het zo makkelijk om mijn trainingen en voortgang bij te houden. Ook de timer en de voorbeeldvideo's zijn echt handig",
      "naam": "Yvo A",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Uitstekende resultaten",
      "recensie": "Ik ben ontzettend blij dat ik deze challenge heb gevolgd. De resultaten waren boven verwachting en ik heb veel bijgeleerd.",
      "naam": "Erik",
      "hoe_lang_geleden": "2 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Aanrader",
      "recensie": "Ik wist nooit wat ik moest doen in de gym. Nu volg ik gewoon mijn schema in de app.",
      "naam": "Laurens",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Constante vooruitgang",
      "recensie": "Dankzij de duidelijke instructies en begeleiding heb ik constante vooruitgang geboekt. Dit programma is zijn geld meer dan waard.",
      "naam": "Tim",
      "hoe_lang_geleden": "1 maand geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Super overzichtelijk",
      "recensie": "Super overzichtelijk en handig. Mijn schema staat altijd voor me klaar.",
      "naam": "Chris",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Maximale resultaten",
      "recensie": "Het schema werd aangepast toen ik mijn doelen bijstelde. Echt maatwerk!",
      "naam": "Frank",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Coaching heeft leven veranderd",
      "recensie": "Stef zijn coaching heeft mijn leven veranderd. Ik heb meer zelfvertrouwen, meer energie en een sterker lichaam dan ooit tevoren. Ik heb nu meer dan 5 maanden coaching.",
      "naam": "Peter",
      "hoe_lang_geleden": "2 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Beste keuze ooit",
      "recensie": "Ik heb veel programma's geprobeerd, maar dit is veruit het beste. Het is specifiek gericht op mijn behoeften en ik heb geweldige resultaten behaald.",
      "naam": "Rik",
      "hoe_lang_geleden": "3 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Goed te volgen",
      "recensie": "Ook met mijn drukke werkrooster kon ik dit perfect inpassen.",
      "naam": "Christiaan V.",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Goede resultaten",
      "recensie": "Ik kreeg precies wat ik nodig had, en alles werd steeds afgestemd op mijn voortgang.",
      "naam": "René van der Laan",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Uitstekende ondersteuning",
      "recensie": "De ondersteuning tijdens het programma was geweldig. Ik voelde me nooit alleen en kreeg altijd snel antwoord op mijn vragen.",
      "naam": "David",
      "hoe_lang_geleden": "4 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Dankbaar",
      "recensie": "Stef heeft me enorm geholpen tijdens de challenge. Ik ben heel dankbaar voor zijn begeleiding en de geweldige resultaten die ik heb behaald.",
      "naam": "Ed",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Aanbevolen aan vrienden",
      "recensie": "Ik heb deze challenge aan veel vrienden aanbevolen omdat ik zelf zo'n goede resultaten heb behaald. Een absolute aanrader.",
      "naam": "Thomas",
      "hoe_lang_geleden": "6 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Waardevolle investering",
      "recensie": "Dit programma is elke cent waard. Het heeft me geholpen om mijn spiermassa te vergroten en waardevolle kennis op te doen in korte tijd.",
      "naam": "Ricardo",
      "hoe_lang_geleden": "7 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Zelfstandig verder",
      "recensie": "Ik heb veel geleerd over spieropbouw en fitness. Nu kan ik zelfstandig verder trainen zonder hulp.",
      "naam": "Freek",
      "hoe_lang_geleden": "8 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Handig bijhouden",
      "recensie": "Het bijhouden van mijn voortgang in het logboek heeft me geholpen consistent te blijven en constant spiermassa op te bouwen.",
      "naam": "Erik K",
      "hoe_lang_geleden": "8 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Super resultaat",
      "recensie": "De 30 dagen hard gewerkt en daardoor super resultaten behaald! Ik voel me geweldig.",
      "naam": "P. B.",
      "hoe_lang_geleden": "8 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Beste tot nu toe",
      "recensie": "Van alle programma's die ik heb gevolgd is dit het beste. De resultaten zijn fantastisch en ik heb veel geleerd.",
      "naam": "Jelmer",
      "hoe_lang_geleden": "3 weken geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Spiermassa toegenomen",
      "recensie": "Ik ben bijna 2 kilo aan spiermassa aangekomen en heb wat vet verloren. Zeer tevreden met het resultaat.",
      "naam": "Noa",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 4
    },
    {
      "titel": "Effectief schema",
      "recensie": "De schema's zijn goed te volgen, zelfs met mijn drukke schema. Stef heeft het perfect opgelost voor mijn situatie.",
      "naam": "Bart",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Perfecte oplossing",
      "recensie": "Zelfs met mijn drukke schema was het programma goed te volgen dankzij de effectieve schema's van Stef. Perfect voor mijn situatie.",
      "naam": "Robert",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    },
    {
      "titel": "Makkelijk te volgen",
      "recensie": "Dankzij Stef's goed gestructureerde schema's kon ik mijn doelen bereiken, zelfs met beperkte tijd. Heel tevreden.",
      "naam": "Faya",
      "hoe_lang_geleden": "5 maanden geleden",
      "aantal_sterren": 5
    }
  ]

  
  
  
  
  
  
 
  return (
    <>

    <div className="reviews-section">
      <div className="page-width">
        <div className="container">
          <div className="col-md-12">

            <div className="reviews-heading">
              <h2>36</h2>
              <h4 style={{ fontSize: '32px' }}>Klanten recensies</h4>
              <div className="stars-rating">
                <img style={{ maxWidth: '130px', marginRight: '12px', marginTop: '6px' }} src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-46.png?v=1707467859" alt=""/>
                <span>
                  4.9 gemiddeld met 36 recensies
                </span> 
              </div>
            </div>
          
          </div>
        </div>

            <div className="reviews-slider mt-4">
              <div className="swiper review-swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="review-title">
                      <span style={{ color: "#333" }}>Mijn favoriete reviews</span>
                    </div>







                    <div className="continuous-slider-container">

                    <Swiper
                      modules={[Pagination, Autoplay]}
                      className="swiper-outer"
                      spaceBetween={20}
                      slidesPerView={'auto'}
                      freeMode={true}
                      loop={true}
                      allowTouchMove={false}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: true
                      }}
                      speed={5000}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {
                        recensies.map((recensie, index) => {
                          if (index < 11) {
                          return (
                            <SwiperSlide key={index}>
                              <div className="review-item" key={index}>
                                <div className="stars-row">
                                  {
                                    recensie.aantal_sterren == 5 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-5.png?v=1707467876" alt=""/>
                                    : recensie.aantal_sterren == 4 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/1708339962-trimmy-Reviews_sterren_4.png?v=1708355428" alt=""/>
                                    : null
                                  }
                                  <span>{ recensie.hoe_lang_geleden }</span>
                                </div>
                                <h5>{ recensie.titel }</h5>
                                <p>
                                  { recensie.recensie }
                                </p>
                                <div className="review-name">
                                  <span>{ recensie.naam }</span>
                                  <div className="verified-badge">
                                    <img src="/img/icons/verified-icon.svg" alt=""/>
                                    <span>Geverifieerde koper</span>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                          } else {
                          return false;
                          }
                        })
                      }
                    </Swiper>
                         
                    <Swiper
                      modules={[Pagination, Autoplay]}
                      className="swiper-outer"
                      spaceBetween={20}
                      slidesPerView={'auto'}
                      freeMode={true}
                      loop={true}
                      allowTouchMove={false}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: true,
                        reverseDirection: true
                      }}
                      speed={5000}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    > 
                      {
                        recensies.map((recensie, index) => {
                          if (index > 10 && index < 25) {
                          return (
                            <SwiperSlide key={index}>
                              <div className="review-item" key={index}>
                                <div className="stars-row">
                                  {
                                    recensie.aantal_sterren == 5 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-5.png?v=1707467876" alt=""/>
                                    : recensie.aantal_sterren == 4 ?
                                      <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/1708339962-trimmy-Reviews_sterren_4.png?v=1708355428" alt=""/>
                                    : null
                                  }
                                  <span>{ recensie.hoe_lang_geleden }</span>
                                </div>
                                <h5>{ recensie.titel }</h5>
                                <p>
                                  { recensie.recensie }
                                </p>
                                <div className="review-name">
                                  <span>{ recensie.naam }</span>
                                  <div className="verified-badge">
                                    <img src="/img/icons/verified-icon.svg" alt=""/>
                                    <span>Geverifieerde koper</span>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                          } else {
                          return false;
                          }
                        })
                      }
                    </Swiper>

                    {/* <Swiper
                      modules={[Pagination, Autoplay]}
                      className="swiper-outer"
                      spaceBetween={20}
                      slidesPerView={'auto'}
                      freeMode={true}
                      loop={true}
                      allowTouchMove={false}
                      autoplay={{
                        delay: 1,
                        disableOnInteraction: true
                      }}
                      speed={5000}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      {
                        recensies.length > 3 ?
                            recensies.map((recensie, index) => {
                              if (index > 24 && index < 35) {
                              return (
                                <SwiperSlide key={index}>
                                  <div className="review-item" key={index}>
                                    <div className="stars-row">
                                      {
                                        recensie.aantal_sterren == 5 ?
                                          <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/stars-5.png?v=1707467876" alt=""/>
                                        : recensie.aantal_sterren == 4 ?
                                          <img src="https://cdn.shopify.com/s/files/1/0645/5541/7768/files/1708339962-trimmy-Reviews_sterren_4.png?v=1708355428" alt=""/>
                                        : null
                                      }
                                      <span>{ recensie.hoe_lang_geleden }</span>
                                    </div>
                                    <h5>{ recensie.titel }</h5>
                                    <p>
                                      { recensie.recensie }
                                    </p>
                                    <div className="review-name">
                                      <span>{ recensie.naam }</span>
                                      <div className="verified-badge">
                                        <img src="/img/icons/verified-icon.svg" alt=""/>
                                        <span>Geverifieerde koper</span>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              )
                            } else {
                              return false;
                            }
                            })
                      : null
                      }
                    </Swiper> */}




                    </div>


                  </div>
                </div>
              </div>
            </div>

      </div>
    </div>
  </>
  )
}

export default ReviewsList;