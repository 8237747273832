import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const WatJeKrijgt = ({ subtitle, title, content, image, imageBlur, bg }) => {
  
  const [opened, { open, close }] = useDisclosure(false);

  return ( 
    <>
      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #7C0000 0%, #0D100F 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h2 className="mt-2 mb-5 text-white text-center big-title">
                Wat krijg je in deze challenge?
                </h2>
            </div>
          </div>

          <div className="row align-items-center" style={{ position: 'relative', top: '45px', paddingBottom: '45px', marginTop: '-45px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                <ul className="list-none">
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Een persoonlijk trainingsschema op maat
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Volledig afgestemd op jouw unieke lichaam, doelen en voortgang.
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Een trainingsschema waarmee je zelf aan de slag kunt
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    2 weken gratis toegang tot de Coaching App
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    50% korting voor de Coaching App voor de laatste 8 weken van de challenge
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-10 col-md-5 mx-auto mt-0 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/trainingsplan/notion/trainingsplan-notion-1.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/trainingsplan/notion/trainingsplan-notion-1.png" alt="" />
              </Modal>
            </div> */}
          </div>

        </div>
      </div>

      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #0D100F 0%, #7C0000 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row align-items-center" style={{ position: 'relative', top: '45px', paddingBottom: '45px', marginTop: '-45px' }}>
            <div className="col-md-4 mx-auto order-md-2">
              <div className="section-heading mb-4 text-left dark-heading">
                {/* <p className="text-white mt-4">
                  Krijg precies te zien hoeveel rondes en hoeveel herhalingen je het beste kunt doen.
                  <br/><br/>
                  Zelfs de rusttijd en het gewicht dat je het beste kunt gebruiken is voor jou uitgedacht!
                </p> */}
                <ul>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Elke sessie zie je precies wat je moet doen
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Houd elke trainingssessie eenvoudig bij in de app
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Krijg feedback op elke trainingssessie voor de beste resultaten
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Tussentijdse aanpassingen in je schema
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-10 col-md-3 mx-auto mt-0 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app.png" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div>

      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #7C0000 0%, #0D100F 100%)', marginBottom: '-90px', border: 'none' }}>
        <div className="container">

          <div className="row align-items-center" style={{ position: 'relative', top: '45px', paddingBottom: '45px', marginTop: '-45px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                {/* <p className="text-white mt-4">
                  Vul gemakkelijk in hoe je de ronde hebt ervaren.
                  <br/><br/>
                  Heb je de ronde gehaald en hoe zwaar was de ronde op een schaal van 1 tot 10?
                  <br/><br/>
                  Aan de hand van deze informatie ontvang je elke week nieuwe workouts waarbij het gewicht, rondes en herhalingen wordt aangepast aan jouw resultaten.
                </p> */}
                <ul>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                      Houd gemakkelijk je rust bij met de timer
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Bekijk bij elke oefening een voorbeeldvideo
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Bekijk je progressie sinds de eerste sessie
                    </span>
                  </li>
                  <li className="text-white mt-4 flex items-center">
                    <svg className="mr-3" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6163 0C5.20079 0 0 5.20079 0 11.6163V25.3837C0 31.7992 5.20079 37 11.6163 37H25.3837C31.7992 37 37 31.7992 37 25.3837V11.6163C37 5.20079 31.7992 0 25.3837 0H11.6163ZM26.2963 15.1103C26.8004 14.6063 26.8004 13.789 26.2963 13.285C25.7923 12.7809 24.9752 12.7809 24.4711 13.285L16.5096 21.2464C16.3416 21.4144 16.0692 21.4144 15.9012 21.2464L12.5289 17.8741C12.0249 17.37 11.2077 17.37 10.7036 17.8741C10.1996 18.3782 10.1996 19.1954 10.7036 19.6995L14.0759 23.0717C15.2519 24.2479 17.1589 24.2479 18.335 23.0717L26.2963 15.1103Z" fill="#CD0C19"/>
                    </svg>
                    <span className="font-bold text-[18px]">
                    Voeg notities toe voor jezelf of voor je coach
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-10 col-md-4 mx-auto mt-0 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app-2.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app-2.png" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div>



      <div className="section" style={{ background: '#fff', paddingBottom: '90px', paddingTop: '60px' }}>
        <div className="container">
          <div className="row" style={{ paddingTop: '60px' }}>
            <div className="col-md-6 mx-auto text-center d-flex justify-content-center flex-column align-items-center">
              
              <p>
              Wacht niet langer en begin vandaag nog aan onze <b>10 Weken Challenge</b>. Ontdek zelf de transformatieve kracht van dit programma en zie de resultaten waar je altijd van hebt gedroomd.
              </p>
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Start Jouw Transformatie
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default WatJeKrijgt;