import AppContext from '../../AppContext';

import './Page.css';

import { useContext, useState } from 'react';

function Waarom() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  return (
    <div className="waarom-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="section-heading dark-heading">
              {/* <h6>Waarom trainen met een schema?</h6> */}
              <h2 className="mt-2 mb-4">Waarom deze challenge gebouwd is</h2>
              <p>
                Als online personal trainer is het mijn passie om mannen te helpen bij het toenemen van spiermassa, verbeterde kracht en prestaties en een betere lichaamssamenstelling.
                <br/><br/>
                Ik wil graag zoveel mogelijk mannen helpen, maar met 1-op-1 online coaching kan ik maar een beperkt aantal mannen helpen.
                <br/><br/>
                Deze <b>Challenge</b> heb ik bedacht zodat ik de lessen en structuur van mijn coachingstrajecten kan aanbieden, zonder de hulp nodig te hebben van persoonlijke coaching.
                <br/><br/>
                Op deze manier kan ik een veel groter aantal mannen helpen om hun dromen waar te maken en zelfvertrouwen te geven in hun eigen lichaam.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 mx-auto">
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                Trainingsuitrusting
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
    Ben je een fitnessliefhebber die zijn training naar een hoger niveau wil tillen en echte resultaten wil zien?
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
    Ben je een drukke professional die op zoek is naar een effectief en efficiënt trainingsprogramma dat past bij jouw hectische levensstijl?
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
    Ben je een atleet die zijn prestaties op het veld of in de sportschool wil verbeteren door spiermassa op te bouwen?
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
    Ben je iemand die al verschillende programma's heeft geprobeerd, maar geen succes heeft gehad en nu op zoek is naar een bewezen methode die echt werkt?
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
    Ben je een coach, personal trainer of fitnessprofessional die op zoek is naar een uitgebreid en resultaatgericht programma om aan je klanten aan te bieden?
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
    Ben je gewoon iemand die zijn lichaam wil transformeren, meer zelfvertrouwen wil opbouwen en een gezondere levensstijl wil leiden?
              </p>
            </div>
          </div>
        </div>

        <div className="row bottom-text">
          <div className="col-md-7 mx-auto my-4">
            <p>
Als je "ja" hebt geantwoord op een van de bovenstaande vragen, dan is de 42 dagen Spiermassa Challenge perfect voor jou!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
              Bekijk aanbieding
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Waarom;