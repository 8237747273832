import AppContext from '../../AppContext';

import './Page.css';

import { useContext, useState } from 'react';

function VoorWie() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  return (
    <div id="voor-wie" className="waarom-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              {/* <h6>Waarom trainen met een schema?</h6> */}
              <h2 className="mt-2 mb-4">Herken jij dit?</h2>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 mx-auto">
            {/* <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
              Sta jij uren in de sportschool, maar krijg je niet de resultaten die je zou verwachten?

              </p>
            </div> */}
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                  Je bent bezig met krachttraining, of je wilt nog beginnen.
                </span>
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                  Je ziet niet de resultaten die je zou willen zien.
                </span>
              </p>        
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Je weet niet precies welke oefeningen of schema’s voor jou het beste werken.
                </span>
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                  Je weet niet precies welk gewicht je moet gebruiken of wanneer je gewicht moet verhogen.
                </span>
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                  Je wilt graag sterker en fitter worden en je zelfverzekerd voelen.
                </span>
              </p>        
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Je hebt een druk (sociaal) leven en hebt moeite om gemotiveerd te blijven.
                </span>        
              </p>
            </div>
          </div>
        </div>

        <div className="row bottom-text">
          <div className="col-md-7 mx-auto my-4">
            <p>
            Als je jezelf hierin herkent, dan is deze <b>4 Weken Challenge</b> precies wat jij nodig hebt!            
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
            Kickstart Mijn Spiergroei!
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoorWie;