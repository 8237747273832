import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const WatJeKrijgt = ({ subtitle, title, content, image, imageBlur, bg }) => {
  
  const [opened, { open, close }] = useDisclosure(false);

  return ( 
    <>
      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #7C0000 0%, #CD0C19 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h2 className="mt-2 mb-5 text-white text-center big-title">
                Wat krijg je in dit programma?
                </h2>
            </div>
          </div>

          <div className="row align-items-center" style={{ position: 'relative', top: '90px', paddingBottom: '90px', marginTop: '-90px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                <p className="text-white mt-4">
                  Geen standaard schema's. 
                  <br/><br/>
                  Elke week ontvang je een op maat gemaakt trainingsplan voor de komende week, dat volledig is afgestemd op jouw unieke lichaam, doelen en voortgang. 
                  <br/><br/>
                  Geen giswerk, alles wordt voor je uitgedacht. Je hoeft het alleen nog te volgen.<br/><br/>
                  Elke oefening is zorgvuldig voor jou gekozen. Je leert waarom een bepaalde oefening bij jouw doelen past.
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4 mx-auto mt-5 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%', borderRadius: '20px !important' }} src="/img/app/vb-schema-op-maat.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/vb-schema-op-maat.png" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div>

      {/* <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #CD0C19 0%, #7C0000 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row align-items-center" style={{ position: 'relative', top: '90px', paddingBottom: '90px', marginTop: '-90px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                <p className="text-white mt-4">
                  Elke oefening is zorgvuldig voor jou gekozen. Je leert waarom een bepaalde oefening bij jouw doelen past.
                </p>
              </div>
            </div>
            <div className="col-6 col-md-7 mx-auto mt-5 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/trainingsplan/trainingsplan-bovenlichaam-oefening-info.jpg" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/trainingsplan/trainingsplan-bovenlichaam-oefening-info.jpg" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div> */}

      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #CD0C19 0%, #7C0000 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row align-items-center" style={{ position: 'relative', top: '90px', paddingBottom: '90px', marginTop: '-90px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                <p className="text-white mt-4">
                  Bekijk een visuele uitleg bij elke oefening.
                  <br/><br/>
                  Zo weet je precies hoe je de oefening correct uitvoert voor maximale resultaten.
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4 mx-auto mt-5 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/vb-video.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/vb-video.png" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div>

      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #7C0000 0%, #CD0C19 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row align-items-center" style={{ position: 'relative', top: '90px', paddingBottom: '90px', marginTop: '-90px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                <p className="text-white mt-4">
                  Krijg precies te zien hoeveel rondes en hoeveel herhalingen je het beste kunt doen.
                  <br/><br/>
                  Zelfs het gewicht dat je kunt gebruiken is voor jou uitgedacht!
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4 mx-auto mt-5 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app.png" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div>

      <div className="text-image-section wat-je-krijgt-section" style={{ background: 'linear-gradient(180deg, #CD0C19 0%, #7C0000 100%)', marginBottom: '-30px', border: 'none' }}>
        <div className="container">

          <div className="row align-items-center" style={{ position: 'relative', top: '90px', paddingBottom: '90px', marginTop: '-90px' }}>
            <div className="col-md-4 mx-auto">
              <div className="section-heading mb-4 text-left dark-heading">
                <p className="text-white mt-4">
                  Vul gemakkelijk in hoe je de ronde hebt ervaren.
                  <br/><br/>
                  Heb je de ronde gehaald en hoe zwaar was de ronde op een schaal van 1 tot 10?
                  <br/><br/>
                  Aan de hand van deze informatie ontvang je elke week nieuwe workouts waarbij het gewicht, rondes en herhalingen wordt aangepast aan jouw resultaten.
                </p>
              </div>
            </div>
            <div className="col-6 col-md-5 mx-auto mt-5 mt-md-0">
              <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app-2.png" alt="" onClick={open} />
              <Modal opened={opened} onClose={close} title="Voorbeeldschema" size="xl" 
                height="75vh"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 3,
              }}>
                <img style={{ position: 'relative', top: '30px', maxWidth: '100%' }} src="/img/app/ui-app-2.png" alt="" />
              </Modal>
            </div>
          </div>

        </div>
      </div>



      <div className="section" style={{ background: '#fff', paddingBottom: '90px', paddingTop: '60px' }}>
        <div className="container">
          <div className="row" style={{ paddingTop: '60px' }}>
            <div className="col-md-6 mx-auto text-center d-flex justify-content-center flex-column align-items-center">
              
              <p>
              Wacht niet langer en begin vandaag nog aan met het <b>Spiergroei Proefpakket</b>. Ontdek zelf de transformatieve kracht van dit programma en zie de resultaten waar je altijd van hebt gedroomd.
              </p>
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Start Jouw Transformatie
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 
export default WatJeKrijgt;