import AppContext from '../../AppContext';

import './Page.css';

import { useContext, useState } from 'react';

function Verwachten() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  return (
    <div className="waarom-section voorwie-section mb-0 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              {/* <h6>Waarom trainen met een schema?</h6> */}
              <h2 className="mt-2 mb-4">Onze Oplossing voor Jouw Problemen</h2>
              <p>
              In onze 10 Weken Challenge bieden wij een no-nonsense aanpak die gericht is op snelle en effectieve resultaten. Hier is hoe wij jou helpen om je doelen te bereiken:              
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col-md-7 mx-auto">
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Persoonlijk en Op Maat:</b> Je ontvangt een schema dat volledig is afgestemd op jouw behoeften.
                {/* <b>Meer zelfvertrouwen:</b> Het behalen van je fitnessdoelen zal niet alleen je lichaam transformeren, maar ook je zelfvertrouwen vergroten. Je zult trots zijn op je prestaties en meer zelfvertrouwen hebben in je dagelijkse leven. */}
                </div>
              </p>
            </div>
            {/* <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Eenvoudige Voedingsrichtlijnen:</b> Onze voedingsaanpak is simpel en effectief. Blijf genieten van je favoriete gerechten met slechts enkele kleine aanpassingen die gemakkelijk in elke levensstijl passen. Hierdoor kun je je voedingsgewoonten volhouden en toch indrukwekkende resultaten behalen.                
                </div>
              </p>
            </div> */}
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Flexibel en Handig:</b> Alternatieve oefeningen en aanpassingen tijdens de challenge.         {/* <b>Betere lichaamssamenstelling:</b> Door het opbouwen van spiermassa en het verliezen van eventueel overtollig vet, kun je een verbetering in je lichaamssamenstelling verwachten. Je zult een strakkere en gespierdere lichaamsbouw ontwikkelen. */}
                </div>
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <div className="item-content">
                <b>Informatief en Ondersteunend:</b> Gedetailleerde uitleg en visuele voorbeelden voor elke oefening.                {/* <b>Betere lichaamssamenstelling:</b> Door het opbouwen van spiermassa en het verliezen van eventueel overtollig vet, kun je een verbetering in je lichaamssamenstelling verwachten. Je zult een strakkere en gespierdere lichaamsbouw ontwikkelen. */}
                </div>
              </p>
            </div>
           
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
            Ontdek meer
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Verwachten;