import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';
import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';

// Import Swiper React components
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Resultaten() {
  const { globalState, updateGlobalState } = useContext(AppContext);


  const images = [
    // "/img/resultaten/resultaat-8.jpg",
    // "/img/resultaten/resultaat-21.jpg",
    // "/img/resultaten/resultaat-1.jpg",
    // "/img/resultaten/resultaat-5.jpg",
    // "/img/resultaten/resultaat-6.jpg",
    // "/img/resultaten/resultaat-27.jpg",
    // "/img/resultaten/resultaat-26.jpg",
    // "/img/resultaten/resultaat-31.jpg",
    // "/img/resultaten/resultaat-3.jpg",
    // "/img/resultaten/resultaat-11.jpg",
    // "/img/resultaten/resultaat-12.jpg",
    // "/img/resultaten/resultaat-13.jpg",
    // "/img/resultaten/resultaat-14.jpg",
    // "/img/resultaten/resultaat-15.jpg",
    // "/img/resultaten/resultaat-20.jpg",
    // "/img/resultaten/resultaat-22.jpg",
    // "/img/resultaten/resultaat-23.jpg",
    // "/img/resultaten/resultaat-24.jpg",
    // "/img/resultaten/resultaat-25.jpg",
    // "/img/resultaten/resultaat-28.jpg",
    // "/img/resultaten/resultaat-29.jpg",
    // "/img/resultaten/resultaat-30.jpg",
    "/img/resultaten/april2024/resultaat1.jpg",
    "/img/resultaten/april2024/resultaat3.jpg",
    "/img/resultaten/april2024/resultaat5.jpg",
    "/img/resultaten/resultaat-21.jpg",
    "/img/resultaten/april2024/resultaat6.jpg",
    "/img/resultaten/april2024/resultaat7.jpg",
    "/img/resultaten/april2024/resultaat8.jpg",
    "/img/resultaten/april2024/resultaat9.jpg",
    "/img/resultaten/resultaat-30.jpg",
    "/img/resultaten/april2024/resultaat10.jpg",
    "/img/resultaten/april2024/resultaat4.jpg",
    "/img/resultaten/april2024/resultaat12.jpg",
    "/img/resultaten/resultaat-8.jpg",
    "/img/resultaten/april2024/resultaat13.jpg",
    "/img/resultaten/april2024/resultaat14.jpg",
    "/img/resultaten/resultaat-6.jpg",
    "/img/resultaten/april2024/resultaat15.jpg",
    "/img/resultaten/april2024/resultaat16.jpg",
    "/img/resultaten/april2024/resultaat17.jpg"
  ]
 
  return (
    <div className="resultaten-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              <h6>Deze mannen gingen je voor</h6>
              <h2 className="mt-2 mb-4">Sta jij hier binnenkort tussen?</h2>
              <p>
                Deze mannen gingen je al voor en behaalden in 42 dagen goede resultaten!
              </p>
            </div>
          </div>
        </div>
      </div>
        <Swiper
          className="mt-5 pt-5"
          modules={[Navigation]}
          spaceBetween={50}
          slidesPerView={3.15}
          navigation
          loop={true}
          autoHeight
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            // when window width is >= 320px
            320: {
              width: 320,
              slidesPerView: 1
            },
            // when window width is >= 640px
            640: {
              width: 320,
              slidesPerView: 1
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2
            },
            // when window width is >= 768px
            991: {
              width: 991,
              slidesPerView: 2
            },
            // when window width is >= 768px
            1025: {
              width: 1025,
              slidesPerView: 2
            }
          }}
        >
          {
            images.map((image, index) => (
              <SwiperSlide key={index} style={{ height: 'auto' }}>
                <div className="resultaten-img">
                <img style={{ maxWidth: '100%' }} src={ image } alt="" />
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
    </div>
  )
}

export default Resultaten;