const USPs = () => {
  return (
    <div className="container">

      <div className="row">
        <div className="col-md-12">
          <h2 className="mt-2 mb-5 text-black text-[32px] font-bold text-center">
            Binnen 4 weken kun je verwachten:
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12"> 
          <div className="usps text-center">
            {/* <div className="usp-item">
              <h5>Unieke begeleiding</h5>
              <p>
                1-op-1 <i>ONLINE</i> begeleiding van een expert op het gebied van spieropbouw bij mannen
              </p>
            </div> */}
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Spiermassa</h5>
              <p className="text-center mx-auto">
              Meer spierdefinitie en kracht.
              </p>
            </div>
            {/* <div className="usp-item">
              <h5>Geen strikt voedingsschema</h5>
              <p>
              Geen gedoe met calorieën tellen of strikte diëten. Je leert hoe je met minimale aanpassingen grote resultaten kunt behalen.              </p>
            </div> */}
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Gestructureerd plan</h5>
              <p className="text-center mx-auto">
              Een duidelijk trainingsplan dat werkt.
              </p>
            </div>
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Zelfvertrouwen</h5>
              <p className="text-center mx-auto">
              Zelfvertrouwen in de sportschool door professionele begeleiding.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default USPs;