import SocialComment from "./SocialComment";

const Facebook = () => {
  return (
    <div className="voorwie-section fb-section" style={{ background: "#fff", paddingBottom: '60px' }} >
      <div className="container">
        <div className="row align-items-center mt-5">
          <div className="col-md-8 mx-auto">
            <h2 className="mb-5" style={{ color: "#000" }}>Dit Zeggen De Deelnemers Over Mijn Methode...</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <img src="/img/hlp-fb-reacties.jpg" alt="" style={{ maxWidth: '100%' }} />
            {/* <SocialComment /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
            Kickstart Mijn Spiergroei!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Facebook;