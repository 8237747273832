import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Stappenplan() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  const [activeScience, setActiveScience] = useState(1);

  return (
    <div className="waarom-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="section-heading dark-heading">
              <h6>Werkwijze</h6> 
              <h2 className="mt-2 mb-4">Hoe ga ik jou helpen?</h2>
              <p>
                Ik heb een proefpakket bedacht om jou de kracht te laten ervaren van op maat gemaakte trainingsschema's, wekelijkse aanpassingen en online begeleiding.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 science-container mx-auto">
            <div className={`science-item ${activeScience == 1 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(1)}>
                1. Intake-formulier invullen
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Geef mij inzicht in jouw doelen, niveau en beschikbare apparatuur. Vul een eenvoudig formulier in, zodat we een plan kunnen maken dat perfect bij jou past.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 2 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(2)}>
                2. Trainingsschema op maat
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Ontvang een persoonlijk trainingsschema dat is afgestemd op jouw doelen, ervaring en agenda. Geen standaardplannen, maar echt maatwerk om jouw resultaten te versnellen. Ik pas het aan tot jij tevreden bent.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 3 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(3)}>
                3. Toegang tot mijn app
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Krijg toegang tot mijn gebruiksvriendelijke app en krijg direct toegang tot je schema, voortgangsmetingen en communicatie met mij als je coach. Alles wat je nodig hebt in één overzicht.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 4 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(4)}>
                4. Je trainingen worden voor je klaargezet
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Elke week plan ik, als jouw coach, je trainingen met de juiste sets, herhalingen en rusttijden. Jij hoeft alleen maar te trainen – ik doe de rest.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 5 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(5)}>
                5. Tussentijdse aanpassingen
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Loop je tegen iets aan? Of maak je sneller progressie dan verwacht? Geen probleem! Ik pas jouw schema aan op basis van jouw voortgang en feedback.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 6 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(6)}>
                6. Sterk, fit en zelfverzekerd
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Volg jouw schema, ontvang feedback en zie jezelf elke week sterker, fitter en zelfverzekerder worden. Jouw doelen zijn dichterbij dan je denkt!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
            Kickstart Mijn Spiergroei!
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stappenplan;