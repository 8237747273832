
import { Box, Button } from '@mantine/core';
import Vimeo from '@u-wave/react-vimeo';
import { useRef, useState } from 'react';
import { IconCaretRight, IconPlay } from '@tabler/icons-react';

const JouwCoach = () => {

  const video = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const toggleVideo = () => {
    console.log(video.current);

    if (isPlaying) {
      video.current.player.pause();
    } else {
      video.current.player.play();
    }

    setIsPlaying(!isPlaying);
  }

  return (
    <div className="coach-section voorwie-section coach-section" style={{ background: '#fff', paddingBottom: '0' }}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6">
            <div className="section-heading text-left dark-heading">
              <h6>Maak kennis met je coach</h6> 
              <h2 className="mt-2 mb-4">Ik help jou naar de beste versie van jezelf</h2>
              <p>
                {/* Stap binnen in de wereld van fitness begeleid door Stef, een ervaren online personal trainer met jarenlange expertise. */}
                Zelf heb ik jaren <b>verspild</b> aan <i>saaie voedingsschema's, generieke trainingsschema's</i> en honderden uren gekeken naar <i>tegenstrijdige informatie</i> op social media.
                <br/><br/>
                Na jaren van proberen en falen heb ik een methode ontwikkeld die <b>wél werkt</b>.
                <br/><br/>
                Laat mij je helpen om de beste versie jezelf te worden. Sterk, fit en zelfverzekerd. 
              </p>
              <div className="col-md-12 d-flex justify-content-start pl-0">
                
                <a href="#offer" className="button button-red d-flex mt-5 justify-content-center">
                Kickstart Mijn Spiergroei!
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 ml-auto">
            <img src="/img/stef-online-coach.png" alt="" />
            {/* <Box style={{ borderRadius: '6px', position: 'relative' }}
                onClick={() => toggleVideo()}>
              <Vimeo
                video="929993857"
                controls={false}
                ref={video}
                className="vimeo-video"
              />

              {
                isPlaying ?
                <div className="vimeo-overlay" onClick={() => toggleVideo()}></div>
                :
                null
              }

              {
                isPlaying ?
                null :
                <Box className="icon-container" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer' }} onClick={() => toggleVideo()}>
                  <IconCaretRight
                    width="50"
                    height="50"
                    color="#000"
                  />
                </Box>
              }
            </Box> */}
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default JouwCoach;